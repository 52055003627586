<template>
  <section>
    <!-- 工具条 -->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
      <el-form class="locale-tool" :inline="true" :model="filter" size="mini">
        <el-form-item>
          <el-input
            v-model="filter.val"
            placeholder="关键字搜索"
            @keyup.native.enter="getData"
          >
            <el-select
              v-model="filter.field"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option label="监测点" value="Name"></el-option>
              <el-option label="地址" value="Addr"></el-option>
              <el-option label="备注" value="Remark"></el-option>
            </el-select>
            <el-button
              slot="append"
              icon="el-icon-refresh"
              @click="handleRefresh"
            ></el-button>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-cascader
            placeholder="所属单位"
            @change="getData"
            v-model="filter.owner"
            :options="customerTree"
            :props="customerProps"
            clearable
            filterable
          />
        </el-form-item>
        <el-form-item>
          <el-cascader
            placeholder="区域"
            @change="getData"
            v-model="filter.area"
            :options="areaTree"
            :props="selAreaProps"
            clearable
            filterable
          />
        </el-form-item>
        <el-form-item>
          <!-- <el-button type='primary' @click='getData'>查询</el-button> -->
          <el-button type="primary" @click="openExportSizeInput">导出</el-button>
          <el-button type="primary" @click="handleAdd">新增</el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <!-- 列表 -->
    <el-col :span="24">
      <el-table
        :data="datalist.content"
        size="mini"
        border
        highlight-current-row
        v-loading="loading"
        @selection-change="selsChange"
        :max-height="clientHeight"
        style="width: 100%"
        :key="itemKey"
      >
        <el-table-column type="selection"/>
        <el-table-column type="index" label="#" align="center" width="55"/>
        <el-table-column
          prop="Name"
          label="监测点"
          width="140"
          show-overflow-tooltip
          header-align="center"
        >
          <template slot-scope="scope">
            <el-popover
              trigger="hover"
              placement="top"
              title="维保人信息"
              :close-delay="120"
              @show="getMaintainer(scope.row.AreaId)"
            >
              <div
                v-if="!maintainer || maintainer.length === 0"
                style="text-align: center; font-weight: bold"
              >
                暂无信息
              </div>
              <template v-else>
                <div v-for="(m, i) in maintainer" :key="i">
                  <b>单位名称: </b>{{ m.Org }}<b> 联系电话: </b>{{ m.Mobile }}
                </div>
              </template>
              <div slot="reference">
                <el-tag size="mini">{{ scope.row.Name }}</el-tag>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          prop="owner_name"
          width="160"
          label="所属单位"
          show-overflow-tooltip
          header-align="center"
        />
        <el-table-column
          prop="Cuisine"
          :formatter="cuisineFormatter"
          label="菜系"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="FanSpeed"
          label="风速"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="FanQuantity"
          label="风量"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="PipeArea"
          label="管道截面面积"
          width="96"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="StoveNum"
          label="灶头数量"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="OfflineJudge"
          label="异常判定[小时]"
          width="106"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="Samplings"
          label="抽样次数"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="EmissionsSill"
          label="超标阈值"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="LinkStatus"
          :formatter="linkFormatter"
          label="是否联动"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="StoveArea"
          label="集气灶面积"
          width="96"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="ExhaustTime"
          label="日均排烟时间"
          width="106"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="Remark"
          label="备注"
          width="120"
          show-overflow-tooltip
          header-align="center"
        />
        <el-table-column
          prop="Addr"
          label="详细地址"
          width="240"
          show-overflow-tooltip
          header-align="center"
        />
        <el-table-column
          prop="area_name"
          show-overflow-tooltip
          label="所属地区"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="Lng"
          label="经度"
          width="96"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="Lat"
          label="纬度"
          width="96"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="FilterInfo"
          label="净化器信息"
          width="160"
          show-overflow-tooltip
          header-align="center"
        />
        <!-- <el-table-column prop='Status' label='使用状态' align='center' header-align='center' /> -->
        <el-table-column
          label="操作"
          align="center"
          width="120"
          fixed="right"
          header-align="center"
        >
          <template slot-scope="scope">
            <i
              title="现场照片"
              @click="handleView(scope.$index, scope.row)"
              class="opt el-icon-view"
            />
            <i
              title="一点一档"
              @click="handlePdfRecordList(scope.row)"
              class="opt el-icon-document"
            />
            <i
              title="设备绑定记录"
              @click="handleRecord(scope.row)"
              class="opt el-icon-time"
            />
            <i
              title="编辑"
              @click="handleEdit(scope.$index, scope.row)"
              class="opt el-icon-edit"
            />
            <i
              title="删除"
              @click="handleDel(scope.$index, scope.row)"
              class="opt el-icon-delete"
            />
          </template>
        </el-table-column>
      </el-table>
    </el-col>

    <!-- 工具条 -->
    <el-col :span="24" class="toolbar">
      <el-button
        type="danger"
        size="mini"
        @click="bulkRemove"
        :disabled="sels.length === 0"
      >批量删除
      </el-button
      >
      <el-pagination
        small
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="filter.page"
        :page-sizes="filter.pageSizes"
        layout="total, sizes, prev, pager, next, jumper"
        :total="datalist.total"
        style="display: inline-block; margin-left: 15px"
      >
      </el-pagination>
    </el-col>

    <!--编辑界面-->
    <el-dialog
      :close-on-click-modal="false"
      :title="dlg.title"
      :visible.sync="dlg.visible"
      @close="handleEditDialogClose"
    >
      <el-form
        :model="formData"
        label-width="124px"
        :rules="formRule"
        ref="form"
        size="mini"
        class="locale-form"
      >
        <el-row class="form-part">
          <el-col :span="2">
            <div>基本信息</div>
          </el-col>
          <el-col :span="10">
            <el-form-item prop="Name" label="监测点">
              <el-input
                placeholder="请输入监测点名称"
                @change="hasChange"
                v-model.trim="formData.Name"/>
            </el-form-item>
            <el-form-item prop="AreaId" label="所属地区">
              <el-cascader
                style="width: 100%"
                placeholder="关键字"
                v-model.trim="formData.AreaId"
                @change="hasChange"
                :options="areaTree"
                :props="areaProps"
                filterable
              />
            </el-form-item>
            <el-form-item prop="Lng" label="经度">
              <el-input
                @change="hasChange"
                placeholder="可直接粘贴到此处"
                v-model.trim="formData.Lng"
                @keyup.ctrl.86.native="handlePaste"
              />
            </el-form-item>
            <a
              style="color: #409eff; text-decoration: none; margin-left: 120px"
              target="_blank"
              href="https://api.map.baidu.com/lbsapi/getpoint/index.html">
              百度坐标拾取系统
            </a>
            <el-form-item prop="StoveLength" label="集气罩长">
              <el-input
                @change="hasChange"
                placeholder="请输入集气罩长(m)"
                v-model.number="formData.StoveLength"
              />
            </el-form-item>
            <el-form-item prop="StoveArea" label="集气灶面积">
              <el-input
                @change="hasChange"
                placeholder="请输入集气灶面积(m²)"
                v-model.number="formData.StoveArea"
              />
            </el-form-item>
            <el-form-item prop="Cuisine" label="菜系">
              <el-select
                @change="hasChange"
                v-model.trim="formData.Cuisine"
                placeholder="选择菜系"
                style="width: 100%"

              >
                <el-option
                  v-for="item in cuisine"
                  :key="item.Id"
                  :label="item.Name"
                  :value="item.Id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="scale" label="企业类型">
              <el-select
                style="width: 100%"
                @change="hasChange"
                placeholder="请选择企业类型"
                v-model.trim="formData.scale">
                <el-option value="大" label="大"/>
                <el-option value="中" label="中"/>
                <el-option value="小" label="小"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item prop="Addr" label="详细地址">
              <el-input
                @change="hasChange"
                placeholder="请输入详细地址"
                v-model.trim="formData.Addr"/>
            </el-form-item>
            <el-form-item prop="Owner" label="所属单位">
              <el-cascader
                style="width: 100%"
                placeholder="关键字"
                v-model.trim="formData.Owner"
                @change="handleOwnerChange"
                :options="customerTree"
                :props="props"
                filterable
              />
            </el-form-item>
            <el-form-item prop="Lat" label="纬度">
              <el-input @change="hasChange" v-model.trim="formData.Lat"/>
            </el-form-item>
            <a
              style="visibility:hidden; color: #409eff; text-decoration: none; margin-left: 120px"
              target="_blank"
              href="https://api.map.baidu.com/lbsapi/getpoint/index.html">
              百度坐标拾取系统
            </a>
            <el-form-item prop="StoveWidth" label="集气罩宽">
              <el-input
                @change="hasChange"
                placeholder="请输入集气罩宽"
                v-model.number="formData.StoveWidth"
              />
            </el-form-item>
            <el-form-item prop="ExhaustTime" label="日均排烟时间">
              <el-input
                @change="hasChange"
                placeholder="请输入日均排烟时间(h)"
                v-model.trim="formData.ExhaustTime"
              />
            </el-form-item>
            <el-form-item prop="StoveNum" label="基准灶头数">
              <el-input
                @change="hasChange"
                placeholder="请输入基准灶头数"
                v-model.number="formData.StoveNum"
              />
            </el-form-item>
            <el-form-item prop="managementTypes" label="经营类型">
              <el-select
                style="width: 100%"
                @change="hasChange"
                placeholder="请选择经营类型"
                v-model.trim="formData.managementTypes">
                <el-option value="经营型" label="经营型"/>
                <el-option value="非经营型" label="非经营型"/>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="form-part">
          <el-col :span="2">
            <div>排放系统信息</div>
          </el-col>
          <el-col :span="10">
            <el-form-item prop="FanFactoryName" label="风机品牌">
              <el-input
                @change="hasChange"
                v-model.trim="formData.FanFactoryName"
                placeholder="请输入风机品牌"/>
            </el-form-item>
            <el-form-item prop="FanPower" label="风机功率">
              <el-input
                @change="hasChange"
                v-model.number="formData.FanPower"
                placeholder="请输入风机功率(kW)"/>
            </el-form-item>
            <el-form-item prop="FanSpeed" label="烟气流速">
              <el-input
                @change="hasChange"
                v-model.number="formData.FanSpeed"
                placeholder="请输入风机功率(m/s)"/>
            </el-form-item>
            <el-form-item prop="filterFactoryName" label="净化器品牌">
              <el-input
                @change="hasChange"
                v-model.trim="formData.filterFactoryName"
                placeholder="请输入净化器品牌"/>
            </el-form-item>
            <el-form-item prop="purifying_rate" label="净化器净化率">
              <el-input
                @change="hasChange"
                v-model.number="formData.purifying_rate"
                placeholder="请输入净化器净化率"/>
            </el-form-item>
            <el-form-item prop="filterPower" label="净化器功率">
              <el-input
                @change="hasChange"
                v-model.number="formData.filterPower"
                placeholder="请输入净化器功率(kW)"/>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item prop="fanFactoryModelId" label="风机型号">
              <el-input
                @change="hasChange"
                v-model.trim="formData.fanFactoryModelId"
                placeholder="请输入风机型号"/>
            </el-form-item>
            <el-form-item prop="fanStandardCurrent" label="风机标准电流">
              <el-input
                @change="hasChange"
                v-model.number="formData.fanStandardCurrent"
                placeholder="请输入风机标准电流(A)"/>
            </el-form-item>
            <el-form-item prop="pipeArea" label="管道截面积">
              <el-input
                @change="hasChange"
                v-model.number="formData.pipeArea"
                placeholder="请输入管道截面积(m²)"/>
            </el-form-item>
            <el-form-item prop="filterFactoryModelId" label="净化器型号">
              <el-input
                @change="hasChange"
                v-model.trim="formData.filterFactoryModelId"
                placeholder="请输入净化器型号"/>
            </el-form-item>
            <el-form-item prop="fanQuantity" label="净化器风量">
              <el-input
                @change="hasChange"
                v-model.number="formData.fanQuantity"
                placeholder="请输入净化器风量(m³/h)"/>
            </el-form-item>
            <el-form-item prop="filterStandardCurrent" label="净化器标准电流">
              <el-input
                @change="hasChange"
                v-model.number="formData.filterStandardCurrent"
                placeholder="请输入净化器标准电流(A)"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row class="form-part">
          <el-col :span="2">监测仪信息</el-col>
          <el-col :span="10">
            <el-form-item prop="factoryName" label="监测仪品牌">
              <el-select
                style="width: 100%"
                @change="handleFactorySelect"
                v-model.trim="formData.factoryName"
                placeholder="请选择监测仪品牌">
                <el-option v-for="factory in factoryList"
                           :key="factory.id"
                           :label="factory.factory_name"
                           :value="factory.factory_name"/>
              </el-select>
            </el-form-item>
            <el-form-item prop="principle" label="检测原理">
              <el-input
                @change="hasChange"
                v-model.trim="formData.principle"
                placeholder="请输入检测原理"/>
            </el-form-item>
            <el-form-item prop="powerSupplyMode" label="接电方式">
              <el-select
                @change="hasChange"
                v-model.trim="formData.powerSupplyMode"
                placeholder="请输入接电方式"
                style="width: 100%">
                <el-option value="风机取电" label="风机取电"/>
                <el-option value="净化器取电" label="净化器取电"/>
                <el-option value="市电" label="市电"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item prop="vocFactoryModelId" label="监测仪型号">
              <el-select
                style="width: 100%"
                @change="hasChange"
                v-model.trim="formData.vocFactoryModelId"
                placeholder="请选择监测仪型号">
                <el-option v-for="item in factoryModelList"
                           :key="item.id"
                           :value="item.id"
                           :label="item.model_name"/>
              </el-select>
            </el-form-item>
            <el-form-item prop="sendMode" label="传输方式">
              <el-select
                @change="hasChange"
                v-model.number="formData.sendMode"
                placeholder="请输入传输方式"
                style="width: 100%">
                <el-option :value="1" label="合并传输"/>
                <el-option :value="2" label="独立传输"/>
                <el-option :value="3" label="两路传输"/>
              </el-select>
            </el-form-item>
            <el-form-item prop="vocMn" label="监测仪序列号">
              <el-input
                @change="hasChange"
                v-model.trim="formData.vocMn"
                placeholder="请输入监测仪型号"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="form-part">
          <el-col :span="2">判断参数</el-col>
          <el-col :span="10">
            <el-form-item prop="emissionsSill" label="油烟阈值">
              <el-input
                @change="hasChange"
                v-model.number="formData.emissionsSill"
                placeholder="请输入油烟阈值(默认为2)"/>
            </el-form-item>
            <el-form-item prop="hydrocarbonSill" label="非甲烷阈值">
              <el-input
                @change="hasChange"
                v-model.number="formData.hydrocarbonSill"
                placeholder="请输入非甲烷阈值(默认为10)"/>
            </el-form-item>
            <el-form-item prop="samplings" label="抽样次数">
              <el-input
                @change="hasChange"
                v-model.number="formData.samplings"
                placeholder="请输入抽样次数(默认为3)"/>
            </el-form-item>
            <el-form-item prop="FanStatus" label="风机状态">
              <el-radio-group
                @input="hasChange"
                v-model="formData.FanStatus">
                <el-radio :label="1">正常</el-radio>
                <el-radio :label="0">不正常</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item prop="granuleSill" label="颗粒物阈值">
              <el-input
                @change="hasChange"
                v-model.number="formData.granuleSill"
                placeholder="请输入颗粒物阈值(默认为5)"/>
            </el-form-item>
            <el-form-item prop="offlineJudge" label="异常判定">
              <el-input
                @change="hasChange"
                v-model.number="formData.offlineJudge"
                placeholder="请输入异常判定(默认为48)"/>
            </el-form-item>
            <el-form-item prop="surpassCalcMethod" label="折算浓度计算方式">
              <el-select
                style="width: 100%"
                @change="hasChange"
                v-model.number="formData.surpassCalcMethod"
              >
                <el-option label="抽样计算" :value="1"/>
                <el-option label="滑动计算" :value="2"/>
              </el-select>
            </el-form-item>
            <el-form-item prop="filterStatus" label="净化器状态">
              <el-radio-group
                @input="hasChange"
                v-model.trim="formData.filterStatus">
                <el-radio :label="1">正常</el-radio>
                <el-radio :label="0">不正常</el-radio>
              </el-radio-group>

            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="form-part">
          <el-col :span="2">上传照片</el-col>
          <el-col :span="20">
            <el-form-item>
              <el-upload
                ref="upload"
                accept=".jpg,.jpeg,.png"
                :show-file-list="false"
                :action="upload()"
                :on-success="handleAvatarSuccess"
                :limit="4"
                :before-upload="hasChange"
              >
                <i class="el-icon-plus avatar-uploader-icon"/>
                <div slot="tip">（最多4张，支持jpg、jpeg、png）</div>
              </el-upload>
              <div class="img-list" v-if="fileList">
                <div class="single-img" v-for="(item, idx) in fileList" :key="idx"
                     style="position: relative; display: inline-block; margin-right: 10px;">
                  <img :src="item">
                  <div class="remove-img el-icon-close" @click="removeImg(idx)"></div>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click.native="dlg.visible = false"
        >取消
        </el-button
        >
        <el-button type="primary" size="mini" @click.native="handleSubmit"
        >提交
        </el-button
        >
      </div>
    </el-dialog>
    <el-dialog :visible.sync="showImg">
      <template v-if="fileList && fileList.length">
        <el-image
          class="view-img"
          v-for="(item, i) in fileList"
          :key="i"
          :src="item"
        >
          <div slot="error" class="image-slot">
            暂无图片
          </div>
        </el-image>
      </template>
      <div v-else>暂无图片</div>
    </el-dialog>
    <!-- 设备绑定记录 -->
    <el-dialog title="设备绑定记录" :visible.sync="recordVisible" width="800px">
      <el-table
        :data="recordlist.content"
        size="mini"
        border
        highlight-current-row
        :max-height="clientHeight - 40"
        style="width: 100%"
      >
        <el-table-column
          prop="DeviceMn"
          label="设备编号"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="BeginAt"
          :formatter="dateFormat"
          label="绑定时间"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="EndAt"
          :formatter="dateFormat"
          label="移机时间"
          align="center"
          header-align="center"
        />
      </el-table>
      <div>
        <el-pagination
          small
          background
          @current-change="handleRecordChange"
          :current-page.sync="filterRecord.page"
          layout="total, prev, pager, next, jumper"
          :total="recordlist.total"
          style="display: inline-block; margin-left: 15px"
        >
        </el-pagination>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click.native="recordVisible = false"
        >关闭
        </el-button
        >
      </div>
    </el-dialog>
    <!-- 生成过的一点一档记录 -->
    <el-dialog
      title="一点一档记录"
      :visible.sync="pdfRecordVisible"
      width="800px"
    >
      <el-form :inline="true" size="mini">
        <el-form-item>
          <el-button type="primary" @click="addPdf">新增一点一档</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="pdfList"
        size="mini"
        border
        highlight-current-row
        :max-height="clientHeight - 40"
        style="width: 100%"
      >
        <el-table-column
          prop="BeginDate"
          label="开始时间"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="EndDate"
          label="结束时间"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="CreatedAt"
          label="生成时间"
          align="center"
          header-align="center"
        />
        <el-table-column label="操作" align="center" header-align="center">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="handleLook(scope.row)"
            >查看
            </el-button
            >
            <!-- <i
              title="删除"
              @click="handleDelRecord(scope.row)"
              class="opt el-icon-delete-solid"
            /> -->
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click.native="pdfRecordVisible = false"
        >关闭
        </el-button
        >
      </div>
    </el-dialog>
    <!-- 一点一档新增选择时间 -->
    <el-dialog :visible.sync="pdfDateShow">
      <el-form
        :model="pdfData"
        label-width="120px"
        :rules="formRule"
        ref="form"
        size="mini"
      >
        <el-form-item label="选择时间段">
          <el-date-picker
            type="daterange"
            v-model="pdfData.time"
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click.native="pdfDateShow = false"
        >取消
        </el-button
        >
        <el-button type="primary" size="mini" @click.native="handleCreatePdf"
        >确定
        </el-button
        >
      </div>
    </el-dialog>
    <!-- 一点一档 -->
    <notificationDialog
      :show="isShowNotificationDialog"
      :showData="isShowNotificationDialogData"
      @on-close="isShowNotificationDialog = false"
    ></notificationDialog>
  </section>
</template>

<script>
import {mapState} from 'vuex'
import {dateFormater, base64ToBlob, ownerJoin} from '@/util/index'
import notificationDialog from '@/component/NotificationDialog'
import {
  getSinglePname,
  getPname,
  export_json,
  getSPname,
  ifNull,
  loadData,
  getUserInfo,
  join,
  toIntArray
} from '@/util/index'
import conf from '@/config'

export default {
  name: "LocaleVerify",
  components: {notificationDialog},
  data() {
    return {
      changeState: false,
      index: 0, // 记录更新index
      sels: [], // 列表选中列
      loading: false,
      datalist: {},
      userInfo: {},
      cuisine: [],
      exportList: [],
      showImg: false,
      maintainer: [],
      org: null,
      fileList: [],
      PowerSupplyModeOptions: [{
        value: '风机取电',
        label: '风机取电'
      }, {
        value: '净化器取电',
        label: '净化器取电'
      }, {
        value: '市电',
        label: '市电'
      }],
      dlg: {
        visible: false,
        state: null,
        title: ''
      },
      filter: {
        val: null,
        owner: null,
        area: null,
        field: 'Name',
        page: 1,
        size: 20,
        pageSizes: [20, 30, 50]
      },
      filterRecord: {
        StartAt: 0,
        Size: 20,
        page: 1,
        size: 20
      },
      localeId: '',
      baseInfo: {},
      recordVisible: false,
      pdfDateShow: false,
      pdfRecordVisible: false,
      pdfList: [],
      pdfData: {},
      isShowNotificationDialog: false, // 是否显示一点一挡
      isShowNotificationDialogData: {
        markLine: {
          symbol: 'none',
          itemStyle: {
            normal: {
              lineStyle: {
                type: 'solid'
                // color: "red",
              },
              label: {
                // show: false,
                position: 'start',
                formatter: '{c} '
              }
            }
          },
          data: []
        },
        dataOption: {
          columns: ['采集时间', '油烟折算浓度'],
          rows: []
        }
      }, // 一点一挡信息
      recordlist: {},
      formData: {
        Name: '',
        AreaId: '',
        Owner: '',
        Lng: '',
        Lat: '',
        Addr: '',
        StoveLength: '',
        StoveWidth: '',
        StoveArea: '',
        ExhaustTime: '',
        scale: '',
        Cuisine: '',
        StoveNum: '',
        managementTypes: '',
        FanFactoryName: '',
        fanFactoryModelId: '',
        FanPower: '',
        fanStandardCurrent: '',
        FanSpeed: '',
        pipeArea: '',
        filterFactoryName: '',
        filterFactoryModelId: '',
        purifying_rate: '',
        fanQuantity: '',
        filterPower: '',
        filterStandardCurrent: '',
        principle: '',
        powerSupplyMode: '',
        vocFactoryModelId: '',
        sendMode: '',
        vocMn: '',
        emissionsSill: '',
        hydrocarbonSill: '',
        samplings: '',
        granuleSill: '',
        offlineJudge: '',
        surpassCalcMethod: '',
        FanStatus: 1,
        filterStatus: 1
      },
      formRule: {
        // Name: [{required: true, message: '名称不可为空', trigger: 'blur'}],
        // Addr: [{required: true, message: '地址不可为空', trigger: 'blur'}],
        // AreaId: [{required: true, message: '地区不可为空', trigger: 'blur'}],
        // Owner: [{required: true, message: '所属单位不可为空', trigger: 'blur'}],
        // Lng: [{required: true, message: '经度不可为空', trigger: 'blur'}],
        // Lat: [{required: true, message: '纬度不可为空', trigger: 'blur'}],
        // StoveArea: [{required: true, message: '集气罩面积不可为空', trigger: 'blur'}],
        // Cuisine: [{required: true, message: '菜系不可为空', trigger: 'blur'}],
      },
      DeviceNum: null,
      itemKey: 1,
      factoryList: [],
      factoryModelList: []
    }
  },
  computed: {
    ...mapState(['areaTree', 'clientHeight', 'locale', 'customerTree']),
    ...mapState({
      props: (state) => Object.assign({}, state.props, {label: 'Org'}),
      customerProps: (state) =>
        Object.assign({}, state.props, {label: 'Org', checkStrictly: true}),
      selAreaProps: (state) =>
        Object.assign({}, state.props, {checkStrictly: true}),
      areaProps: (state) => Object.assign({}, state.props)
    })
  },
  created() {
    try {
      const user = getUserInfo()
      this.userInfo = user.Info
    } catch (e) {
      this.$message.error(e)
    }
    this.$get('admin/listAllCuisine').then((res) => {
      this.cuisine = res
      // console.log("cusine",this.cuisine)
    })
    // 更新所属单位（不排除未来需要更新更多数据
    this.$get('admin/listBasicDataOfCustomer').then(res => {
      this.$store.dispatch('setCustomer', {content: res.Customer})
    })
    this.getData()
  },
  methods: {
    // 新增一点一档
    addPdf() {
      this.pdfData = {}
      this.getLast7Days()
      this.pdfDateShow = true
      this.pdfRecordVisible = false
    },
    // 一点一档--历史记录
    handlePdfRecordList(row) {
      this.localeId = row.Id
      // this.baseInfo = {
      //   LocaleId: row.Id,
      //   CustomerName: row.Name,
      //   Addr: row.Addr,
      //   CustomerBlNo: row.CustomerBlNo,
      //   CustomerContact: row.Contact,
      //   CustomerMobile: row.Mobile
      // }
      this.pdfRecordVisible = true
      this.pdfList = []
      this.$post('admin/queryLocaleArchive', {LocaleId: this.localeId})
        .then((res) => {
          this.pdfList = res
        })
        .catch(() => {
        })
    },
    handleCreatePdf() {
      this.pdfDateShow = false
      const par = {
        LocaleId: this.localeId,
        AcquitAtBegin: this.pdfData.time[0] / 1000,
        AcquitAtEnd: this.pdfData.time[1] / 1000
      }
      const list = []
      let customForm = {}
      this.$post('admin/listDataDetectorDailySumDaysByLocaleId', par)
        .then((res) => {
          list.push(res)
          customForm = {...res.customer}
        })
        .then((response) => {
          // const tpl = { mn: '010000A8900016F018010029' };
          const tpl = {localeId: this.localeId}
          tpl['AcquitAtBegin'] = this.pdfData.time[0] / 1000
          tpl['AcquitAtEnd'] = this.pdfData.time[1] / 1000
          this.$post('admin/queryDataDetectorDaily', tpl).then((res) => {
            this.isShowNotificationDialogData = Object.assign(
              {},
              this.isShowNotificationDialogData,
              customForm
            )
            this.isShowNotificationDialogData.LocaleId = this.localeId
            this.isShowNotificationDialogData.beginDate = dateFormater(
              tpl['AcquitAtBegin'],
              false
            )
            this.isShowNotificationDialogData.endDate = dateFormater(
              tpl['AcquitAtEnd'],
              false
            )
            let data
            this.isShowNotificationDialogData.dataOption.rows = []
            for (var i = tpl['AcquitAtBegin']; i <= tpl['AcquitAtEnd']; i += 86400) {
              data = res.find((e) => e.AcquitAt === i)
              this.isShowNotificationDialogData.dataOption.rows.push({
                采集时间: dateFormater(i, false),
                油烟折算浓度: data ? data.CEmissions : 0,
                颗粒物折算浓度: data ? data.CGranule : 0,
                非甲烷总烃折算浓度: data ? data.CHydrocarbon : 0
              })
            }
            this.isShowNotificationDialog = true
            this.isShowNotificationDialogData.tableData = list
            this.isShowNotificationDialogData.markLine.data.push({
              lineStyle: {
                color: '#00A1FF'
              },
              yAxis: res[0].LocaleEmissionsSill || 0
            }, {
              lineStyle: {
                color: '#F5B049'
              },
              yAxis: res[0].LocaleGranuleSill || 0
            }, {
              lineStyle: {
                color: '#009999'
              },
              yAxis: res[0].LocaleHydrocarbonSill || 0
            })
          })
        })
        .catch(() => {
        })
    },
    handleDelRecord(row) {
      this.$post('admin/removeLocaleArchive', {Id: row.Id})
        .then((res) => {
          this.$post('admin/queryLocaleArchive', {LocaleId: this.localeId})
            .then((res) => {
              this.pdfList = res
            })
            .catch(() => {
            })
        })
        .catch(() => {
        })
    },
    getLast7Days() {
      this.pdfData.time = []
      const now = new Date()
      now.setDate(now.getDate() - 1)
      now.setHours(0, 0, 0, 0)
      this.pdfData.time[1] = now.getTime()
      now.setDate(now.getDate() - 30)
      this.pdfData.time[0] = now.getTime()
    },
    // 查看历史一点一档
    handleLook(row) {
      this.download(row.LocaleId, row.Archive)
    },
    // 下载图片
    download(name, imgData) {
      this.downloadFile(name, imgData)
    },
    // 下载
    downloadFile(fileName, content) {
      const aLink = document.createElement('a')
      const blob = base64ToBlob(content) // new Blob([content]);
      const evt = document.createEvent('HTMLEvents')
      evt.initEvent('click', true, true) // initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
      aLink.download = fileName
      aLink.href = URL.createObjectURL(blob)
      // aLink.dispatchEvent(evt);
      // aLink.click()
      aLink.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })
      ) // 兼容火狐
    },
    hasChange() {
      this.changeState = true
    },
    getPname(r, c) {
      return getPname(this.customerTree, r[c.property], 'Org').join('/')
    },
    getArea(r, c) {
      return getSinglePname(this.areaTree, r[c.property])
    },
    // uploadAction(req) {
    //   var fileObj = req.file;
    //   var formData = new FormData();
    //   formData.append("IMG", fileObj);
    //   formData.append("_ID", this.localeId);
    //   this.$post("admin/localeUpload", formData);
    // },
    upload() {
      return conf.uploadApi + '/uploadToOss'
    },
    handleAvatarSuccess(res, file) {
      this.fileList.push(res.Data)
    },
    openExportSizeInput() {
      this.$prompt(`请输入导出的数据量（总量：${this.datalist.total} 条）`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[0-9]*$/,
        inputErrorMessage: '只能填入数字'
      }).then(({value}) => {
        this.handleExport(parseInt(value))
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消'
        })
      })
    },
    handleExport(num) {
      const param = {
        StartAt: (this.filter.page - 1) * this.filter.size,
        Size: num,
        Param: {IsDownload: true}
      }
      if (this.filter.owner && this.filter.owner.length > 0) {
        const owner = `${ownerJoin(this.filter.owner, this.userInfo)}`
        param.Param['Owner'] = owner
      }
      if (this.filter.field && this.filter.val) {
        param.Param[this.filter.field] = this.filter.val
      }
      if (this.filter.area && this.filter.area.length != 0) {
        param.Param['Area'] = join(this.filter.area)
      }
      this.$post("admin/listLocale", param).then(res => {
        let url = res.url
        const link = document.createElement('a')
        link.setAttribute('href', url)
        link.click()
      })
    },
    getMaintainer(id) {
      this.$get('admin/getAreaMaintainer', {areaId: id})
        .then((res) => {
          this.maintainer = res
        })
        .catch(() => {
        })
    },
    getData: function () {
      this.loading = true
      const param = {
        StartAt: (this.filter.page - 1) * this.filter.size,
        Size: this.filter.size,
        Param: {}
      }
      if (this.filter.owner && this.filter.owner.length > 0) {
        const owner = `${ownerJoin(this.filter.owner, this.userInfo)}`
        param.Param['Owner'] = owner
      }
      if (this.filter.field && this.filter.val) {
        param.Param[this.filter.field] = this.filter.val
      }
      if (this.filter.area && this.filter.area.length != 0) {
        param.Param['Area'] = join(this.filter.area)
      }
      this.$post("admin/listLocale", param).then(res => {
        this.datalist.content = res.content
        this.datalist.total = res.total
        this.itemKey = Math.random()
        this.loading = false
      })


    },
    handleRecord(row) {
      this.localeId = row.Id
      this.getRecordList()
    },
    getRecordList() {
      const filterRecord = JSON.parse(JSON.stringify(this.filterRecord))
      filterRecord.StartAt = filterRecord.size * (filterRecord.page - 1)
      filterRecord.Size = filterRecord.size
      filterRecord['Param'] = {Locale_Id: this.localeId}
      this.$post('admin/listLocaleDeviceRecord', filterRecord)
        .then((res) => {
          this.recordlist = res
          this.recordVisible = true
        })
        .catch(() => {
        })
    },
    handleRecordChange(page) {
      this.filterRecord.page = page
      this.getRecordList()
    },
    dateFormat(r, c) {
      return dateFormater(r[c.property], true)
    },
    handleOwnerChange(v) {
      this.org = getSPname(this.customerTree, v, 'Org')
      this.changeState = true
    },
    handleView(index, row) {
      this.fileList = []
      const par = {Param: {id: row.Id}}
      this.$post('admin/listLocale', par)
        .then((res) => {
          const data = res.content[0]
          this.fileList = data.LocalePicS
        })
        .catch(() => {
        })
      this.showImg = true
    },
    handleBeforeRemove(file) {
      return this.$confirm('确认删除吗？').then(() => {
        const reg = /res\/(.*)\.[a-zA-Z]+$/
        this.$get('admin/delLocaleAt', {fid: file.url.match(reg)[1]})
      })
    },
    removeImg(idx) {
      this.changeState = true
      this.fileList.splice(idx, 1)
    },
    transformCuisine(k) {
      for (let i = 0, len = this.cuisine.length; i < len; i++) {
        if (this.cuisine[i].Id === k) {
          return this.cuisine[i].Name
        }
      }
      return ''
    },
    cuisineFormatter(r, c) {
      return this.transformCuisine(r[c.property])
    },
    linkFormatter(r, c, v) {
      return v === 1 ? '是' : '否'
    },
    handlePaste() {
      this.changeState = true
      const arr = this.formData.Lng.split(',')
      this.formData.Lng = arr[0]
      this.formData.Lat = arr[1]
    },
    handleRefresh(clear = true) {
      if (clear) {
        this.filter.page = 1
        this.filter.val = null
        this.filter.owner = null
        this.filter.area = null
      }
      loadData(
        this,
        (res) => {
          this.$store.dispatch('setLocale', res).then(() => {
            this.getData()
          })
        },
        3
      )
    },
    selsChange: function (sels) {
      this.sels = sels
    },
    // 改变页容量
    handleSizeChange(size) {
      this.filter.size = size
      this.getData()
    },
    // 翻页
    handleCurrentChange(page) {
      this.filter.page = page
      this.getData()
    },
    formInit() {
      this.formData = {
        Name: '',
        AreaId: '',
        Owner: '',
        Lng: '',
        Lat: '',
        Addr: '',
        StoveLength: '',
        StoveWidth: '',
        StoveArea: '',
        ExhaustTime: '',
        scale: '',
        Cuisine: '',
        StoveNum: '',
        managementTypes: '',
        FanFactoryName: '',
        fanFactoryModelId: '',
        FanPower: '',
        fanStandardCurrent: '',
        FanSpeed: '',
        pipeArea: '',
        filterFactoryName: '',
        filterFactoryModelId: '',
        purifying_rate: '',
        fanQuantity: '',
        filterPower: '',
        filterStandardCurrent: '',
        principle: '',
        powerSupplyMode: '',
        vocFactoryModelId: '',
        sendMode: '',
        vocMn: '',
        emissionsSill: '',
        hydrocarbonSill: '',
        samplings: '',
        granuleSill: '',
        offlineJudge: '',
        surpassCalcMethod: '',
        FanStatus: 1,
        filterStatus: 1
      }
    },
    handleAdd: function () {
      this.dlg.title = '新增'
      this.$post('admin/listFactory', {}).then(res => {
        this.factoryList = res.content
      })
      this.dlg.visible = true
      this.dlg.state = true
      this.fileList = []
      this.formInit()
    },
    handleEdit: function (index, row) {
      this.dlg.title = '编辑'
      this.dlg.visible = true
      this.dlg.state = false
      this.index = index
      this.fileList = []
      this.formInit()
      const par = {Param: {id: row.Id}}
      this.$post('admin/listLocale', par)
        .then((res) => {
          const data = res.content[0]
          this.fileList = data.LocalePicS
          this.formData = Object.assign({}, data, {
            LocalePicS: []
          })
          this.formData.Owner = data.Owner === '' ? [] : toIntArray(data.Owner)
          this.formData.AreaId = data.AreaId === '' ? [] : toIntArray(data.AreaId)
          this.formData.SurpassCalcMethod = data.SurpassCalcMethod
          this.formData.FanStandardCurrent = data.FanStandardCurrent
          this.formData.FilterStandardCurrent = data.FilterStandardCurrent
          this.formData.SendMode = data.SendMode === 0 ? 1 : data.SendMode // 0 已弃用，后端代码未修改，前端解决
          this.formData.SurpassCalcMethod = data.SurpassCalcMethod === 0 ? null : data.SurpassCalcMethod
          this.formData.Cuisine = data.Cuisine === 0 ? null : data.Cuisine
          this.DeviceNum = res.DeviceNum
        })
        .catch(() => {
        })
    },
    // 批量删除
    bulkRemove: function () {
      const ids = this.sels.map((item) => item.Id).toString()
      this.$confirm('确认删除选中记录吗？', '提示', {
        type: 'warning'
      })
        .then(() => {
          this.$post('admin/bulkRemoveLocale', {ids: ids})
            .then((res) => {
              this.getData()
            })
            .catch(() => {
            })
        })
        .catch(() => {
        })
    },
    handleDel: function (index, row) {
      this.$confirm('确认删除该记录吗?', '提示', {
        type: 'warning'
      })
        .then(() => {
          const para = {id: row.Id}
          this.$get('admin/delLocale', para)
            .then(() => {
              this.getData()
            })
            .catch(() => {
            })
        })
        .catch(() => {
        })
    },
    handleSubmit: function () {
      if (!this.changeState) {
        this.dlg.visible = false
        return
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$confirm('确认提交吗？', '提示', {})
            .then(() => {
              const para = Object.assign({}, this.formData)
              para.Owner = join(para.Owner)
              para.AreaId = join(para.AreaId)
              para.LocalePicS = this.fileList
              if (this.dlg.state) {
                if (this.org !== '') {
                  para.Name = this.org + '-' + para.Name
                } else {
                  para.Name = this.userInfo['org'] + '-' + para.Name
                }
              }


              this.$post('admin/saveLocale', para)
                .then(() => {
                  this.dlg.visible = false
                })
                .catch(() => {
                })
            })
            .catch(() => {
            })
        }
      })
    },
    handleEditDialogClose: function () {
      if (this.changeState) {
        this.changeState = false
        this.handleRefresh(false)
      }
      this.$refs.upload.clearFiles() // 清除已上传图片，重置上传数量限制
      if (this.$refs['form']) {
        this.$refs['form'].resetFields()
      }
    },
    /**
     * 监测仪品牌选择后调用
     * @param value
     */
    handleFactorySelect(value) {
      this.changeState = true
      let param = {
        StartAt: 0,
        Size: 20,
        Param:
          {
            FactoryName: value,
            ModelType: 1
          }
      }
      this.$post('admin/listFactoryModel', param).then(res => {
        this.factoryModelList = res.content
      })
    }
  }
}
</script>

<style lang="scss" scoped>


.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 78px;
  text-align: center;
}

.single-img > img {
  width: 160px;
  height: 160px;
}

.view-img {
  width: 178px;
  height: 178px;
  margin-right: 10px;
}

.remove-img {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  color: #FFF;
  text-align: center;
  background: rgba(0, 0, 0, .5);
  transition: transform .3s;
  -webkit-transition: transform .3s;
}

.remove-img:hover {
  cursor: pointer;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}


.locale-tool {
  .el-select--mini ::v-deep .el-input--suffix {
    width: 120px;
  }
}

.form-part {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  border-color: #eeeeee;
}
</style>
